<template>
  <el-dialog
    :before-close="handleClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :title="title"
    :visible.sync="visible"
    center
    top="5vh"
    width="756px"
  >
    <el-form ref="form" v-loading="queryLoading" :model="form" :rules="rules" label-width="auto" size="small">
      <el-row>
        <el-col :span="12">
          <el-form-item :label="$t('title.PlatForm')" prop="plat">
            <el-select
              v-model="form.plat"
              :disabled="!isAdd"
              :placeholder="$t('page.selectPlaceholder')"
              clearable
              filterable
              @change="handlePlatformChange"
            >
              <el-option
                v-for="item in platformList"
                :key="item.platformId"
                :label="item.platformName"
                :value="item.platformId"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('title.site')" prop="site">
            <el-select
              v-model="form.site"
              :disabled="!isAdd"
              :placeholder="$t('page.selectPlaceholder')"
              clearable
              filterable
              @change="handleSiteChange"
            >
              <el-option
                v-for="item in siteList"
                :key="item.siteId"
                :label="item.siteName"
                :value="item.siteId"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('title.shopName')" prop="shop">
            <el-select
              v-model="form.shop"
              :disabled="!isAdd"
              :placeholder="$t('page.selectPlaceholder')"
              clearable
              filterable
              @change="getComplianceWarehouseList"
            >
              <el-option
                v-for="item in shopList"
                :key="item.shopId"
                :label="item.shopName"
                :value="item.shopId"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <vxe-table
      ref="table"
      :data="tableData"
      :loading="queryLoading"
      :scroll-x="{ enabled: false }"
      :scroll-y="{ enabled: false }"
      :show-overflow="false"
      align="center"
      border
      max-height="300"
      size="small"
    >
      <!--序号-->
      <vxe-column :title="$t('page.No')" type="seq" />
      <!--平台虚拟仓-->
      <vxe-column :title="$t('page.PlatformVirtualWarehouse')">
        <template v-slot="{row}">
          <el-input
            v-model="row.virtualName"
            :placeholder="$t('page.inputPlaceholder')"
            clearable
          />
        </template>
      </vxe-column>
      <!--履约仓-->
      <vxe-column :title="$t('page.ComplianceWarehouse')">
        <template v-slot="{row}">
          <el-select
            v-model="row.warehouseId"
            :placeholder="$t('page.selectPlaceholder')"
            clearable
            filterable
            multiple
            @remove-tag="(val) => handleDeleteSign(val, row)"
          >
            <el-option
              v-for="item in complianceWarehouseList"
              :key="item.id"
              :label="item.wareCode"
              :value="item.id"
            />
          </el-select>
        </template>
      </vxe-column>
      <!--操作-->
      <vxe-column :title="$t('page.operate')">
        <template v-slot="{rowIndex}">
          <i
            class="el-icon-delete"
            style="cursor: pointer;color: #409EFF;font-size: 24px;"
            @click="handleDelete(rowIndex)"
          />
        </template>
      </vxe-column>
    </vxe-table>
    <el-button
      icon="el-icon-circle-plus-outline"
      style="margin: 16px 0"
      type="primary"
      @click="handleAdd"
    >
      {{ $t('title.Add') }}
    </el-button>
    <template #footer>
      <el-button @click="handleClose">{{ $t('order.Cancel') }}</el-button>
      <el-button type="primary" @click="handleSubmit">{{ $t('page.sure') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { getShopList, getSiteList } from '@/api/select'
import { addVirtualWarehouse, getVirtualWarehouseDetail, updateVirtualWarehouse } from './apis/addDialog'
import { getComplianceWarehouseList } from '../apis'

export default {
  name: 'AddDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: null
    },
    platformList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      queryLoading: false,
      complianceWarehouseList: [],
      siteList: [],
      shopList: [],
      form: {
        plat: '',
        site: '',
        shop: ''
      },
      rules: {
        plat: [{ required: true, message: this.$t('page.selectPlaceholder'), trigger: 'change' }],
        site: [{ required: true, message: this.$t('page.selectPlaceholder'), trigger: 'change' }],
        shop: [{ required: true, message: this.$t('page.selectPlaceholder'), trigger: 'change' }]
      },
      tableData: []
    }
  },
  computed: {
    isAdd() {
      return !this.row
    },
    title() {
      return this.isAdd ? this.$t('title.Add') : this.$t('title.Edit')
    },
    queryListParams() {
      const { plat, site, shop } = this.form
      return {
        platformId: plat ? [plat] : [],
        siteId: site ? [site] : [],
        shopId: shop ? [shop] : []
      }
    },
    submitFn() {
      return this.isAdd ? addVirtualWarehouse : updateVirtualWarehouse
    },
    submitParams() {
      const { plat, site, shop } = this.form
      const virtualHouseList = this.tableData.map(e => ({
        ...e,
        warehouseId: e.warehouseId.join(',')
      }))
      if (this.row) {
        return {
          plat,
          site,
          shop,
          virtualHouseList
        }
      }
      const platformName = this.platformList.find(e => e.platformId === plat)?.platformName
      const siteName = this.siteList.find(e => e.siteId === site)?.siteName
      const shopName = this.shopList.find(e => e.shopId === shop)?.shopName
      return {
        plat: platformName,
        site: siteName,
        shop: shopName,
        virtualHouseList
      }
    }
  },
  watch: {
    visible: {
      async handler(val) {
        if (val && this.row) {
          this.queryLoading = true
          const { plat, site, shop } = this.row
          const { datas } = await getVirtualWarehouseDetail({
            plat,
            site,
            shop
          }).finally(() => {
            this.queryLoading = false
          })
          this.form = {
            plat,
            site,
            shop
          }
          await this.getComplianceWarehouseList()
          this.tableData = (datas?.virtualHouseList || []).map(e => ({
            ...e,
            warehouseId: e.warehouseId?.split(',') || []
          }))
          this.$nextTick(() => {
            this.$refs.form.clearValidate()
          })
        }
      }
    }
  },
  methods: {
    async getComplianceWarehouseList() {
      this.tableData = this.tableData.map(e => ({
        ...e,
        warehouseId: []
      }))
      const { plat, site, shop } = this.submitParams
      if (!plat || !site || !shop) {
        this.complianceWarehouseList = []
        return
      }
      const { datas } = await getComplianceWarehouseList({
        plat,
        site,
        shop
      })
      this.complianceWarehouseList = (datas || []).map(e => ({
        ...e,
        id: e.id.toString()
      }))
    },
    handlePlatformChange() {
      this.form.site = ''
      this.form.shop = ''
      this.complianceWarehouseList = []
      this.tableData = this.tableData.map(e => ({
        ...e,
        warehouseId: []
      }))
      this.getSiteList()
    },
    handleSiteChange() {
      this.form.shop = ''
      this.complianceWarehouseList = []
      this.tableData = this.tableData.map(e => ({
        ...e,
        warehouseId: []
      }))
      this.getShopList()
    },
    async getSiteList() {
      const { platformId } = this.queryListParams
      if (!platformId.length) {
        this.siteList = []
        return
      }
      const { datas } = await getSiteList({
        platformId
      })
      this.siteList = datas || []
    },
    async getShopList() {
      const { platformId, siteId } = this.queryListParams
      if (!platformId.length || !siteId.length) {
        this.shopList = []
        return
      }
      const { datas } = await getShopList({
        platformId,
        siteId
      })
      this.shopList = datas || []
    },
    handleDeleteSign(val, row) {
      this.$confirm('将删除该虚拟仓下的所有规则，是否确定？', '提示', {
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {

      }).catch(() => {
        row.warehouseId.push(val)
      })
    },
    handleDelete(index) {
      this.$confirm('将删除该虚拟仓下的所有规则，是否确定？', '提示', {
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.tableData.splice(index, 1)
      }).catch(() => {

      })
    },
    handleAdd() {
      this.tableData.push({
        virtualName: '',
        warehouseId: []
      })
      setTimeout(() => {
        this.$refs.table.scrollToRow(this.tableData.at(-1), '')
      })
    },
    validate() {
      return new Promise(resolve => {
        this.$refs.form.validate(valid => {
          if (!valid) {
            resolve(false)
            return
          }
          if (!this.tableData.length) {
            this.$message.warning(this.$t('page.inputTip'))
            resolve(false)
            return
          }
          const res = this.tableData.every(e => e.virtualName && e.warehouseId.length)
          if (!res) {
            this.$message.warning(this.$t('page.inputTip'))
          }
          resolve(res)
        })
      })
    },
    handleClose() {
      this.form = {
        plat: '',
        site: '',
        shop: ''
      }
      this.tableData = []
      this.complianceWarehouseList = []
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
      this.$emit('update:row', null)
      this.$emit('update:visible', false)
    },
    async handleSubmit() {
      const valid = await this.validate()
      if (valid) {
        await this.$confirm(this.$t('title.batchprocessing'), this.$t('page.Prompt'), {
          closeOnClickModal: false,
          type: 'warning',
          beforeClose: async(action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = instance.cancelButtonLoading = true
              const { code, msg } = await this.submitFn(this.submitParams).finally(() => {
                instance.cancelButtonLoading = instance.confirmButtonLoading = false
              })
              if (code === 0) {
                this.$message.success(msg)
                this.$emit('refresh')
                this.handleClose()
                done()
              }
            } else {
              done()
            }
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
