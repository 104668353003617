import request from '@/utils/request'

export function getVirtualWarehouseList(params, signal) {
  return request({
    url: `${process.env['VUE_APP_OMS_API']}ofc-api-rest/virtualHouse/query`,
    params,
    signal
  })
}

export function updateVirtualWarehouseStatus(data) {
  return request({
    url: `${process.env['VUE_APP_OMS_API']}ofc-api-rest/virtualHouse/updateStatus`,
    method: 'post',
    data
  })
}
