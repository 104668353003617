import request from '@/utils/request'

export function getComplianceWarehouseList(params) {
  return request({
    url: `${process.env['VUE_APP_OMS_API']}ofc-api-rest/warehouse/listWarehouseByBaseData`,
    params
  })
}

export function getVirtualWarehouseList(params) {
  return request({
    url: `${process.env['VUE_APP_OMS_API']}ofc-api-rest/virtualHouse/listVirtualHouseByBaseData`,
    params
  })
}
