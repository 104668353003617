import request from '@/utils/request'

export function addVirtualWarehouse(data) {
  return request({
    url: `${process.env['VUE_APP_OMS_API']}ofc-api-rest/virtualHouse/add`,
    method: 'post',
    data
  })
}

export function updateVirtualWarehouse(data) {
  return request({
    url: `${process.env['VUE_APP_OMS_API']}ofc-api-rest/virtualHouse/update`,
    method: 'post',
    data
  })
}

export function getVirtualWarehouseDetail(params) {
  return request({
    url: `${process.env['VUE_APP_OMS_API']}ofc-api-rest/virtualHouse/detail`,
    method: 'post',
    params
  })
}
